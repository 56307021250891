<template>
  <div class="public-detail" v-wechat-title="`${$route.meta.title}_双优云桥-智慧研修平台`">
    <div class="public-detail-wrapper manage-wrapper">
      <!-- <el-breadcrumb separator="/" class="bread-crumb">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/home/manage/public' }">公开课管理</el-breadcrumb-item>
        <el-breadcrumb-item>课程详情</el-breadcrumb-item>
      </el-breadcrumb> -->
      <div class="public-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="课程详情" name="first">
            <el-form ref="form" :model="courseDetail" label-width="180px">
              <el-form-item label="课程名称：" :required="true">
                <p>{{ courseDetail.name }}</p>
              </el-form-item>
              <el-form-item label="课程简介：">
                <p>
                  {{ courseDetail.courseExplain || '无' }}
                </p>
              </el-form-item>
              <el-form-item label="课程类型：">
                <p>{{ courseDetail.typeName || '无'}}</p>
              </el-form-item>
              <el-form-item label="课程学科：">
                <p>{{ courseDetail.subjectName || '无'}}</p>
              </el-form-item>
              <el-form-item label="年级：">
                <p>{{ courseDetail.gradeName || '无'}}</p>
              </el-form-item>
              <el-form-item label="主题封面：">
                <p v-if="!courseDetail.cover">无</p>
                <img v-else :src="downloadURL + courseDetail.cover" alt="" class="cover" />
              </el-form-item>
              <el-form-item label="授课老师：">
                <p>{{ courseDetail.teacherName || '无'}}</p>
              </el-form-item>
              <el-form-item label="课程标签：">
                <p>{{ courseDetail.courseLabel || '无'}}</p>
              </el-form-item>
              <!-- <el-form-item label="教材：">
              <p>{{ courseDetail.teachingMaterial }}</p>
            </el-form-item> -->
              <el-form-item label="课节附件：">
                <p v-if="!courseDetail.lessonFileNames">无</p>
                <ul class="file-list" v-else>
                  <li class="csp" v-for="(item, index) in courseDetail.lessonFileNames" :key="index">
                    <span class="name" @click="openFile(item)">{{ item.filename }}</span>
                  </li>
                </ul>
              </el-form-item>
              <el-form-item label="视频：" :required="true">
                <ul class="file-list">
                  <li class="csp">
                    <span class="name"
                      @click="openFile(courseDetail.videoName)">{{ courseDetail.videoName.filename || '无' }}</span>
                  </li>
                </ul>
              </el-form-item>
              <el-form-item label="视频网址：">
                <p>{{ courseDetail.link || '无'}}</p>
              </el-form-item>
              <!-- <el-form-item label="能力标准：">
              <p>{{ courseDetail.targetIds }}</p>
            </el-form-item> -->
              <el-form-item label="能力标准：">
                <p v-if="!courseDetail.targetIds">无</p>
                <el-cascader v-else style="width:100%;" v-model="courseDetail.targetIds" :options="powerList"
                  :props="{ multiple: true, label: 'title', value: 'id' }" disabled></el-cascader>
              </el-form-item>
              <el-form-item class="el-form-item-btns" label-width="0px">
                <el-button type="primary" @click="goEdit">编辑</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        courseDetail: {
          videoName: {
            filename: ''
          }
        },
        activeName: "first",
        powerList: [],
      };
    },
    methods: {
      async getCourseInfo() {
        let id = this.$route.params.id
        let resData = await this.$Api.Course.getPublicDetail(id);
        // console.log(resData);
        this.courseDetail = resData.data
      },
      goEdit() {
        let id = this.$route.params.id
        this.$router.push(`/public/edit/${id}?breadNumber=4`)
      },
      openFile(item) {
        window.open(this.downloadURL + item.identifier)
      },
      //获取能力标准
      async getPowerlist() {
        let resData = await this.$Api.Form.getAllPower()
        this.powerList = resData.data;
      },
    },
    mounted() {
      this.getCourseInfo();
      this.getPowerlist();
    },
    // activated() {
    //   this.getCourseInfo();
    //   this.getPowerlist();
    // }
  };
</script>

<style lang="less" scoped></style>